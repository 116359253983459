<template>
  <div class="mentor_schedule_seting">
    <div>
      <div class="item_wrap row">
        <div class="title">固定的上课周期</div>
        <div class="add" @click="handleShowPicker"></div>
      </div>
      <div class="item_content">
        <div class="item" v-for="(item, index) in timeList" :key="index">
          <!-- 每 周一 00:00 到 00:00-->
          <span>每</span>
          <span>{{ DayWeek[item.dayForWeek - 1] }}</span>
          <span>{{ item.startHour }}</span>
          <span>到</span>
          <span>{{ item.endHour }}</span>
          <div class="eidt_img" @click="editItem(index, 'time')"></div>
          <div class="minus" @click="minusItem(index)"></div>
        </div>
        <div class="empty" v-if="!timeList.length">暂无固定的上课周期</div>
        <p class="tips">
          小提示：你可以在这里录入每周固定可以上课的时间，我们会参考这些时间段安排课次，请尽量不要频繁改动噢~
        </p>
      </div>
    </div>
    <div>
      <div class="item_wrap row">
        <div class="title">临时调整的日期或时段</div>
        <div class="add" @click="showTimePicker"></div>
      </div>
      <div class="item_content">
        <div class="item" v-for="(item, index) in dateList" :key="index">
          <!-- 每 周一 00:00 到 00:00-->

          <span style="flex: 2; padding-right: 32px">{{
            filterDate(item.singleDate)
          }}</span>
          <span>{{ item.noFreeTime ? "" : item.startHour }}</span>
          <span :class="{ noFreeSpan: item.noFreeTime }">{{
            item.noFreeTime ? "当日无空闲" : "到"
          }}</span>
          <span>{{ item.noFreeTime ? "" : item.endHour }}</span>
          <div class="eidt_img" @click="editItem(index, 'date')"></div>
          <div class="minus" @click="minusItem(index, 'date')"></div>
        </div>
        <div class="empty" v-if="!dateList.length">
          暂无临时调整的日期或时段
        </div>
        <p class="tips">
          小提示：在这里添加‘某天’的可上课时段。比如添加的时段为2022年1月1日08:00~10:00，则表示2022年1月1日这天的08:00~10:00可以上课，班主任在安排这天的课次时尽量只按照这个时间进行排课
        </p>
      </div>
    </div>

    <div style="padding-bottom: 100px">
      <div class="item_wrap row">
        <div class="title">接课状态</div>
      </div>
      <div class="item_content">
        <div class="row" style="padding-top: 1px">
          <span class="status">当前可接课</span>
          <Switcher v-model="planSwitch" @change="SwitchChange"></Switcher>
        </div>
        <p class="tips" style="padding-top: 23px">
          小提示：你可以在这里更改接受课程安排的意向，不影响已有的课程安排
        </p>
      </div>
    </div>

    <Button v-if="false" size="large" class="save_btn" @click="handleSavev2"
      >保存</Button
    >

    <Popup
      v-model="timePopupVisible"
      position="bottom"
      @closeOnClickModal="clearSet"
    >
      <Picker
        :slots="timeSlots"
        @change="onTimeValuesChange"
        show-toolbar
        ref="timePicker"
      >
        <span class="text-tip">请选择开始时间</span>
        <span
          class="mint-datetime-action mint-datetime-confirm"
          @click="handleNext"
          >下一步</span
        >
      </Picker>
    </Popup>

    <Popup
      v-model="durationPopupVisible"
      position="bottom"
      @closeOnClickModal="clearSet"
    >
      <!-- <Picker
        :slots="durationSlots"
        @change="onDurationValuesChange"
        show-toolbar
        ref="timePicker"
      >
        <span class="text-tip">请选择持续时间</span>
        <span
          class="mint-datetime-action mint-datetime-confirm"
          @click="addItem"
          >确认</span
        >
      </Picker> -->
      <van-picker
        show-toolbar
        cancel-button-text="请选择结束时间"
        :columns="columns"
        @change="onDurationValuesChange"
        style="height: 260px"
        @confirm="addItem"
        ref="endtimePicker"
      />
    </Popup>
    <van-popup
      v-model="startTimeShow"
      position="bottom"
      @close="PopupHide"
      @opened="PopupShow"
    >
      <div class="checkBox">
        <span class="key">当日无空闲时间</span
        ><span
          >是：<input
            id="checkBox"
            type="checkbox"
            @change="checkChange(true)" />否：<input
            id="checkBox2"
            type="checkbox"
            @change="checkChange(false)"
        /></span>
      </div>
      <van-datetime-picker
        v-model="currentDate"
        @cancel="timePickerClose"
        :cancel-button-text="'请选择开始时间'"
        :type="addFreeTimeForm2.noFreeTime ? 'date' : 'datetime'"
        :confirm-button-text="confirmText"
        @confirm="startTimeChange"
        :formatter="formatter"
        :filter="filter"
        :min-date="minDate"
        :max-date="maxDate"
        @change="startPickerChange"
      />
    </van-popup>
    <van-popup v-model="endTimeShow" position="bottom">
      <!-- <van-datetime-picker
        v-model="value2"
        :cancel-button-text="'请选择结束时间'"
        @cancel="timePickerClose"
        type="time"
        @confirm="endTimeChange"
        @change="endTimePickerChange"
        :filter="filter2"
        :formatter="formatter"
        :min-hour="minHour"
        :min-minute="minMinute"
      /> -->
      <van-picker
        show-toolbar
        cancel-button-text="请选择结束时间"
        :columns="columns2"
        @change="endTimePickerChange"
        @confirm="endTimeChange"
      />
    </van-popup>
  </div>
</template>
<script>
import Button from "../../components/Button.vue";
import { Popup, Picker, Switch, MessageBox } from "mint-ui";
import { Hours, Minutes, Duration, DayWeek } from "../../../api/constant";
import {
  postTimeSet,
  getFreeTimeQuery,
  addFreeTime,
  editFreeTime,
  freeTimeEdit,
  freeTimeDel,
} from "../../../api/mentor";

function getNewItem(item) {
  if (!item) return {};
  // ["每", "周三", "00", "时", "00", "分"]
  return {
    dayForWeek: 1 + DayWeek.findIndex((v) => v === item[1]),
    startHour: `${item[2]}:${item[4]}`,
    endHour: "",
  };
}

const DurationAll = Duration();

export default {
  name: "mentor-schedule-seting",
  data() {
    return {
      DayWeek,
      addOredit: "add",
      itemIndex: 0,
      watingSet: { step1: [], step2: [] },
      planSwitch: true,
      timeList: [],
      dateList: [],
      updateList: [],
      deleteList: [],
      timePopupVisible: false,
      durationPopupVisible: false,
      showSave: false,
      timeSlots: [
        {
          values: ["每"],
          textAlign: "center",
        },
        {
          values: DayWeek,
          textAlign: "center",
        },
        {
          values: Hours(),
          textAlign: "center",
        },
        {
          values: ["时"],
          textAlign: "center",
        },
        {
          values: Minutes(),
          textAlign: "center",
        },
        {
          values: ["分"],
          textAlign: "center",
        },
      ],
      DurationAll,
      durationSlots: [
        {
          values: DurationAll.map((v) => v.label),
          textAlign: "center",
        },
      ],
      value: "",
      currentDate: new Date(),
      value2: "",
      minHour: 0,
      minMinute: 0,
      startTimeShow: false,
      endTimeShow: false,
      timeType: "",
      startTime: "",
      endTime: "",
      weekType: 3,
      addFreeTimeForm2: {
        linkId: this.$linkId,
        type: 2,
        weekType: 4,
        startHour: "",
        endHour: "",
        noFreeTime: false,
      },
      confirmText: "下一步",
      minHour2: 0,
      columns: [
        {
          values: [],
        },
        {
          values: ["时"],
        },
        {
          values: [],
        },
        {
          values: ["分"],
        },
      ],
      columns2: [
        {
          values: [],
        },
        {
          values: ["时"],
        },
        {
          values: [],
        },
        {
          values: ["分"],
        },
      ],
      minDate: new Date(),
      maxDate: new Date(new Date().getFullYear() + 1, 10, 1),
      maxMinute: 59,
    };
  },
  computed: {
    moreThan20() {
      // 已有20条时隐藏添加按钮
      return this.timeList.length >= 20;
    },
  },
  methods: {
    startPickerChange(e) {
      console.log(e.getColumnValue(3).substring(0, 2));
      if (e.getColumnValue(3).substring(0, 2) == 23) {
        this.maxMinute = 0;
      } else {
        this.maxMinute = 59;
      }
      console.log(this.maxMinute);
    },
    endTimePickerChange(picker, values) {
      console.log("change");
      if (values[0] == "23") {
        this.columns2[2].values = ["00", "15", "30", "45", "59"];
      } else if (values[0] == this.watingSet.step1[2]) {
        this.columns2[2].values = [];
        for (
          let index = this.watingSet.step1[4] * 1;
          index < 60;
          index = index + 15
        ) {
          let num = index + "";
          this.columns2[2].values.push(num.padStart(2, "0"));
        }
      } else {
        this.columns2[2].values = ["00", "15", "30", "45"];
      }
    },
    filter(type, options) {
      if (type === "minute") {
        if (this.maxMinute != 0) {
          return options.filter((option) => {
            return option % 15 === 0;
          });
        } else {
          return options.filter((option) => {
            return option == 0;
          });
        }
      }

      return options;
    },
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },
    PopupShow() {
      if (
        this.dateList[this.itemIndex]?.noFreeTime === true &&
        this.addOredit != "add"
      ) {
        this.addFreeTimeForm2.noFreeTime = true;
        document.querySelector("#checkBox").checked = true;
        document.querySelector("#checkBox2").checked = false;
        this.confirmText = "确定";
      } else {
        document.querySelector("#checkBox").checked = false;
        document.querySelector("#checkBox2").checked = true;
        this.addFreeTimeForm2.noFreeTime = false;
      }
    },
    PopupHide() {
      document.querySelector("#checkBox").checked = false;
      document.querySelector("#checkBox2").checked = true;
      this.addFreeTimeForm2.noFreeTime = false;
      this.confirmText = "下一步";
    },
    timePickerClose() {
      // document.querySelector("#checkBox").checked = false;
      // this.addFreeTimeForm2.noFreeTime = false;
      // this.startTimeShow = false;
      // this.endTimeShow = false;
      // this.confirmText = "下一步";
    },
    // 编辑按钮
    editItem(index, type) {
      this.addOredit = "edit";
      this.itemIndex = index;

      if (type === "time") {
        if (this.moreThan20) return;
        this.timePopupVisible = true;
      } else {
        this.minHour = 0;
        this.minMinute = 0;
        this.startTimeShow = true;
      }
    },
    // 格式化短期时间日期
    filterDate(str) {
      var date = new Date(str);
      var m = date.getMonth() + 1;
      var d = date.getDate();
      return m + "月" + d + "日";
    },
    // 当日无空闲时间
    checkChange(e) {
      if (e) {
        document.querySelector("#checkBox").checked = true;
        document.querySelector("#checkBox2").checked = false;
      } else {
        document.querySelector("#checkBox").checked = false;
        document.querySelector("#checkBox2").checked = true;
      }

      this.addFreeTimeForm2.noFreeTime = e;
      e ? (this.confirmText = "确认") : (this.confirmText = "下一步");
    },
    showTimePicker(name) {
      console.log(this.dateList.length);
      if (this.dateList.length >= 20) {
        return this.$toast("短期单日空闲时间设置条数达到上限");
      }

      this.addOredit = "add";
      this.itemIndex = 0;
      this.minHour = 0;
      this.minMinute = 0;
      this.timeType = name;
      this.startTimeShow = true;
    },
    // 时间
    startTimeChange(e) {
      console.log(this.time(e));
      this.startTime = e;

      this.addFreeTimeForm2.singleDate = this.time(e);
      this.startTimeShow = false;
      if (this.addFreeTimeForm2.noFreeTime) {
        this.addFreeTimeForm2.endHour = "";
        this.addFreeTimeForm2.startHour = "";
        if (this.addOredit === "add") {
          this.addFreeTime();
        } else {
          // this.dateList[this.itemIndex].startHour = "";
          // this.dateList[this.itemIndex].endHour = "";
          // this.dateList[this.itemIndex].singleDate = this.time(e);
          // this.dateList[this.itemIndex].noFreeTime =
          //   this.addFreeTimeForm2.noFreeTime;
          this.freeTimeEdit({
            endHour: "",
            startHour: "",
            singleDate: this.time(e),
            noFreeTime: this.addFreeTimeForm2.noFreeTime,
            weekType: 4,
            id: this.dateList[this.itemIndex].id,
          });
          // if (
          //   this.updateList.some(
          //     (i) => i.id === this.dateList[this.itemIndex].id
          //   )
          // ) {
          //   this.updateList.forEach((i) => {
          //     if (i.id === this.dateList[this.itemIndex].id) {
          //       i = this.dateList[this.itemIndex];
          //     }
          //   });
          // } else {
          //   this.updateList.push(this.dateList[this.itemIndex]);
          // }
          // this.showSave = true;
        }
      } else {
        this.addFreeTimeForm2.startHour = this.time2(e);
        this.minHour = this.addFreeTimeForm2.startHour.substring(0, 2);
        if (this.minHour == "23") {
          this.addFreeTimeForm2.startHour = "23:00";
        }
        this.minMinute = this.addFreeTimeForm2.startHour.substring(3, 6);
        let hour = Number(this.minHour);
        console.log(this.addFreeTimeForm2.startHour);
        if (hour != 23) {
          hour = Number(this.minHour) + 1;
        }
        console.log(hour);
        this.columns2[0].values = [];
        for (let h = hour; h < 24; h++) {
          const val = h < 10 ? `0${h}` : `${h}`;
          this.columns2[0].values.push(val);
        }
        if (this.minHour == "23") {
          this.columns2[2].values = ["59"];
        } else if (this.minHour == "22") {
          this.columns2[2].values = ["00", "15", "30", "45", "59"];
        } else {
          this.columns2[2].values = ["00", "15", "30", "45"];
        }
        this.endTimeShow = true;
      }
    },
    endTimeChange(e) {
      this.endTime = `${e[0]}:${e[2]}`;
      this.addFreeTimeForm2.endHour = `${e[0]}:${e[2]}`;
      this.endTimeShow = false;
      this.addFreeTimeForm2.weekType = 4;
      if (this.addOredit === "edit") {
        // this.dateList[this.itemIndex].singleDate =
        //   this.addFreeTimeForm2.singleDate;
        // this.dateList[this.itemIndex].startHour =
        //   this.addFreeTimeForm2.startHour;
        // this.dateList[this.itemIndex].endHour = `${e[0]}:${e[2]}`;
        // this.dateList[this.itemIndex].noFreeTime =
        //   this.addFreeTimeForm2.noFreeTime;
        this.freeTimeEdit({
          endHour: `${e[0]}:${e[2]}`,
          startHour: this.addFreeTimeForm2.startHour,
          singleDate: this.addFreeTimeForm2.singleDate,
          noFreeTime: this.addFreeTimeForm2.noFreeTime,
          weekType: 4,
          id: this.dateList[this.itemIndex].id,
        });
        // if (
        //   this.updateList.some((i) => i.id === this.dateList[this.itemIndex].id)
        // ) {
        //   this.updateList.forEach((i) => {
        //     if (i.id === this.dateList[this.itemIndex].id) {
        //       i = this.dateList[this.itemIndex];
        //     }
        //   });
        // } else {
        //   this.updateList.push(this.dateList[this.itemIndex]);
        // }
        // this.showSave = true;
        // this.editFreeTime(this.dateList[this.itemIndex]);
      } else {
        // this.dateList.push({
        //   endHour: this.addFreeTimeForm2.endHour,
        //   startHour: this.addFreeTimeForm2.startHour,
        //   singleDate: this.addFreeTimeForm2.singleDate,
        // });
        this.addFreeTime();
      }
      this.clearSet();
    },
    // 新增时间
    async addFreeTime() {
      try {
        await addFreeTime({
          ...this.addFreeTimeForm2,
          planSwitch: this.planSwitch,
        });
        this.confirmText = "下一步";
        this.initData();
      } catch (e) {
        console.log(e);
      }
    },
    // 编辑时间
    async editFreeTime(params) {
      try {
        await editFreeTime({
          linkId: this.$linkId,
          type: 2,
          updateList: [params],
          planSwitch: this.planSwitch,
        });

        this.initData();
      } catch (e) {
        console.log(e);
      }
    },
    time(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      // var h = date.getHours();
      // h = h < 10 ? "0" + h : h;
      // var minute = date.getMinutes();
      // minute = minute < 10 ? "0" + minute : minute;
      // var second = date.getSeconds();
      // second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d;
    },
    time2(date) {
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      return h + ":" + minute;
    },
    handleShowPicker() {
      if (this.moreThan20) {
        return this.$toast("固定周期空闲时间设置条数达到上限");
      }

      this.addFreeTimeForm2.noFreeTime = false;
      this.itemIndex = 0;
      this.addOredit = "add";
      this.timePopupVisible = true;
    },
    handleNext() {
      // if (!this.watingSet.step1[2]) return this.$toast("请选择");
      console.log(this.$refs.timePicker.getSlotValue(1));
      this.watingSet.step1 = [
        "每",
        this.$refs.timePicker.getSlotValue(1),
        this.$refs.timePicker.getSlotValue(2),
        "时",
        this.$refs.timePicker.getSlotValue(4),
        "分",
      ];
      console.log(this.watingSet.step1);
      this.watingSet.step2 = "";
      let minHour = 0;
      this.columns = [
        {
          values: [],
        },
        {
          values: ["时"],
        },
        {
          values: [],
        },
        {
          values: ["分"],
        },
      ];
      minHour = this.watingSet.step1[2] * 1;
      if (minHour != "23") {
        minHour += 1;
      }
      for (let h = minHour; h < 24; h++) {
        const val = h < 10 ? `0${h}` : `${h}`;
        this.columns[0].values.push(val);
      }
      if (this.watingSet.step1[2] * 1 == "23") {
        this.columns[2].values = ["59"];
        this.watingSet.step2 = ["23", "时", "59", "分"];
      } else if (minHour == "23") {
        this.columns[2].values = ["00", "15", "30", "45", "59"];
      } else {
        this.columns[2].values = ["00", "15", "30", "45"];
      }
      this.timePopupVisible = false;
      this.durationPopupVisible = true;
    },
    minusItem(index, type) {
      this.itemIndex = index;
      if (type == "date") {
        // this.deleteList.push(this.dateList[this.itemIndex]);

        // this.editSave([this.dateList[this.itemIndex]]);
        this.freeTimeDel(this.dateList[this.itemIndex]);
        this.dateList.splice(index, 1);
      } else {
        // this.deleteList.push(this.timeList[this.itemIndex]);

        // this.editSave([this.timeList[this.itemIndex]]);
        this.freeTimeDel(this.timeList[this.itemIndex]);
        this.timeList.splice(index, 1);
      }

      // this.showSave = true;
    },
    addItem() {
      this.watingSet.step2 = [
        this.$refs.endtimePicker.getColumnValue(0),
        "时",
        this.$refs.endtimePicker.getColumnValue(2),
        "分",
      ];

      const { step1, step2 } = this.watingSet;
      if (!step1.length || !step2.length) return this.$toast("请选择");

      let newItem = getNewItem(step1);
      newItem.endHour = step2[0] + ":" + step2[2];
      // 找到 x小时xx分钟 对应的 小时 分钟数
      // const Index = this.DurationAll.findIndex((v) => v.label === step2[0]);
      // const { hour, min } = this.DurationAll[Index];
      // const startHour = newItem.startHour.split(":");
      // // 02:30 + 1 30; 23:30 1 0
      // let delta = 0;
      // let endMin = Number(startHour[1]) + min;
      // if (endMin >= 60) {
      //   delta = 1;
      // }
      // endMin = endMin >= 60 ? endMin - 60 : endMin;
      // endMin = endMin < 10 ? `0${endMin}` : endMin;

      // let endHour = Number(startHour[0]) + hour + delta;

      // endHour = endHour >= 24 ? endHour - 24 : endHour;
      // endHour = endHour < 10 ? `0${endHour}` : endHour;

      // newItem.endHour = `${endHour}:${endMin}`;
      // if (Number(endHour) < Number(startHour[0])) {
      //   newItem.endHour = "23:59";
      // }

      this.durationPopupVisible = false;

      if (this.addOredit === "edit") {
        // this.timeList[this.itemIndex].dayForWeek = newItem.dayForWeek;
        // this.timeList[this.itemIndex].startHour = newItem.startHour;
        // this.timeList[this.itemIndex].endHour = newItem.endHour;
        this.freeTimeEdit({
          endHour: newItem.endHour,
          startHour: newItem.startHour,
          dayForWeek: newItem.dayForWeek,
          weekType: 3,
          id: this.timeList[this.itemIndex].id,
        });

        // if (
        //   this.updateList.some((i) => i.id === this.timeList[this.itemIndex].id)
        // ) {
        //   this.updateList.forEach((i) => {
        //     if (i.id === this.timeList[this.itemIndex].id) {
        //       i = this.timeList[this.itemIndex];
        //     }
        //   });
        // } else {
        //   this.updateList.push(this.timeList[this.itemIndex]);
        // }
        // this.showSave = true;
        // this.editFreeTime(this.timeList[this.itemIndex]);
      } else {
        this.addFreeTimeForm2.weekType = 3;
        this.addFreeTimeForm2.dayForWeek = newItem.dayForWeek;
        this.addFreeTimeForm2.startHour = newItem.startHour;
        this.addFreeTimeForm2.endHour = newItem.endHour;
        // this.timeList.push(newItem);
        this.addFreeTime();
      }

      this.clearSet();
      // this.showSave = true;
    },
    clearSet() {
      // 潘多拉的魔盒
      // this.watingSet = {step1: [], step2: []}
    },
    onTimeValuesChange(picker, values) {
      console.log(values, picker.getSlotValue(1));
      if (picker.getSlotValue(2) == "23") {
        console.log(true);
        this.timeSlots[4].values = ["00"];
      } else {
        this.timeSlots[4].values = ["00", "15", "30", "45"];
      }
      this.watingSet.step1 = [
        "每",
        picker.getSlotValue(1),
        picker.getSlotValue(2),
        "时",
        picker.getSlotValue(4),
        "分",
      ];
    },
    onDurationValuesChange(picker, values) {
      console.log(values);
      if (values[0] == "23") {
        this.columns[2].values = ["00", "15", "30", "45", "59"];
      } else if (values[0] == this.watingSet.step1[2]) {
        this.columns[2].values = [];
        for (
          let index = this.watingSet.step1[4] * 1;
          index < 60;
          index = index + 15
        ) {
          let num = index + "";
          this.columns[2].values.push(num.padStart(2, "0"));
        }
        console.log(this.columns[2].values);
      } else {
        this.columns[2].values = ["00", "15", "30", "45"];
      }
      this.watingSet.step2 = JSON.parse(JSON.stringify(values));
    },
    async handleSavev2() {
      MessageBox.confirm("是否保存修改内容").then((action) => {
        console.log(action);
        this.editTime();
      });
    },
    async editTime() {
      try {
        await editFreeTime({
          linkId: this.$linkId,
          type: 2,
          updateList: this.updateList,
          deleteList: this.deleteList,
          planSwitch: this.planSwitch,
        });
        // this.showSave = false;
        // this.$router.push("/mentor/mine");
      } catch (e) {
        // this.$toast(e)
      }
    },
    async handleSave() {
      this.timeList = [...this.timeList, ...this.dateList];

      try {
        await postTimeSet({
          timeList: this.timeList,
          planSwitch: this.planSwitch,
        });
        this.showSave = false;
        this.$router.push("/mentor/mine");
      } catch (e) {
        // this.$toast(e)
      }
    },
    async editSave(arr) {
      try {
        await editFreeTime({
          linkId: this.$linkId,
          type: 2,
          updateList: [],
          deleteList: arr,
          planSwitch: this.planSwitch,
        });
      } catch (e) {
        // this.$toast(e)
      }
    },
    async initData() {
      // const { timeList, planSwitch } = await getTimeQuery({
      //   teacherId: this.$linkId,
      // });
      // this.timeList = timeList || [];

      let tList = [];
      let dList = [];
      const res = await getFreeTimeQuery();
      this.planSwitch = res.planSwitch || false;
      res.list.forEach((i) => {
        if (i.weekType === 3) {
          tList.push(i);
        } else if (i.weekType === 4) {
          dList.push(i);
        }
      });

      this.timeList = tList;
      this.dateList = dList;
    },

    // backChange() {
    //   history.pushState(null, null, window.location.hash);
    //   if (this.showSave) {
    //     MessageBox.confirm("未保存是否退出？", {
    //       title: "提示",
    //       confirmButtonText: "放弃修改",
    //     }).then((action) => {
    //       console.log(action);
    //       this.$router.push("/mentor/mine");
    //     });
    //   } else {
    //     this.$router.push("/mentor/mine");
    //   }
    // },
    // 编辑时间
    async freeTimeEdit(params) {
      try {
        await freeTimeEdit({
          linkId: this.$linkId,
          type: 2,
          ...params,
        });

        this.initData();
      } catch (e) {
        console.log(e);
      }
    },
    async freeTimeDel(params) {
      try {
        await freeTimeDel({
          linkId: this.$linkId,
          type: 2,
          ...params,
        });

        // this.initData();
      } catch (e) {
        console.log(e);
      }
    },
    SwitchChange() {
      // this.planSwitch = !this.planSwitch;
      // this.$forceUpdate();
      console.log(this.planSwitch);
      this.editTime();
    },
  },
  components: {
    Button,
    Picker,
    Popup,
    Switcher: Switch,
  },
  mounted() {
    document.title = "排课设置";

    // if (window.history && window.history.pushState) {
    //   history.pushState(null, null, window.location.hash);
    //   window.addEventListener("popstate", this.backChange, false);
    // }
  },
  created() {
    this.initData();
  },
  destroyed() {
    // window.removeEventListener("popstate", this.backChange, false);
  },
};
</script>
<style lang="stylus">
.mint-popup {
  width: 100%;
  padding-bottom: 30px;
}

.van-ellipsis {
  font-size: 18px;
}
</style>
<style lang="stylus" scoped>
@import '../../../common.styl';
@import '../../../theme.styl';

.mentor_schedule_seting {
  background: #fff;
  min-height: 100vh;
  position: relative;

  .item_wrap {
    width: 100%;
    height: 48px;
    background: #F3F3F3;
    padding: 0 15px;

    .title {
      font-size: fz16px;
      color: #393939;
    }

    .add {
      width: 20px;
      height: 20px;
      bgFill();
      background-image: url('./images/加.png');
    }
  }

  .item_content {
    padding: 26px 15px 20px;

    .item {
      display: flex;
      align-items: center;

      &+.item {
        margin-top: 20px;
      }

      span {
        font-size: fz14px;
        color: #10213F;
        padding-right: 16px;
        flex: 1;
      }

      .noFreeSpan {
        flex: 3;
        padding-right: 0;
      }

      .btn {
        width: 70px;
        height: 36px;
        border-radius: 4px;
        border: 1px solid #D8DCE5;
        text-align: center;
        line-height: 36px;
        font-size: fz14px;
        color: #10213F;
        cursor: pointer;

        &+.btn {
          margin-left: 12px;
        }
      }

      .aline {
        width: 10px;
        height: 1px;
        margin: 0 16px 0 17px;
        background: #D8D8D8;
      }

      .minus {
        width: 20px;
        height: 20px;
        bgFill();
        background-image: url('./images/减.png');
        margin-left: 15px;
      }

      .eidt_img {
        width: 20px;
        height: 20px;
        bgFill();
        background-image: url('./images/edit2.png');
        margin-left: 10px;
      }
    }
  }

  .text-tip {
    display: inline-block;
    width: 50%;
    text-align: center;
    line-height: 40px;
    font-size: fz14px;
    color: #666;
  }

  .status {
    font-size: fz16px;
    font-family: AlibabaPuHuiTiR;
    color: #10213F;
  }

  .tips, .empty {
    font-size: fz12px;
    color: #8A8D91;
    line-height: 18px;
  }

  .tips {
    margin-top: 26px;
  }

  .save_btn {
    position: absolute;
    bottom: 64px;
    left: 50%;
    transform: translateX(-50%);
  }

  .checkBox {
    display: flex;
    justify-content: space-between;
    padding: 10px 16px;
    color: #969799;
    font-size: 14px;

    input {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    input:nth-child(1) {
      margin-right: 18px;
    }
  }
}
</style>
<style lang="stylus">
@import '../../../theme.styl';

.mentor_schedule_seting {
  .mint-switch-core {
    position: relative;
    width: 44px !important;
    height: 22px !important;

    &:before, &:after {
      width: 22px !important;
      height: 100% !important;
    }

    &:after {
      left: auto;
      right: 19px;
    }
  }

  .mint-switch-input:checked + .mint-switch-core {
    border-color: theme !important;
    background-color: theme !important;
  }

  .picker-slot.picker-slot-center {
    flex: 1;
  }
}
</style>
